import styled from "styled-components";
import Colors from "../../themes/Colors";

const CartBudgetStyleHolder = styled.div`
  .cartBudget {
    position: static;
    z-index: 996;
    height: auto;
    background-color: ${Colors.primary};
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 5px;
    width: 88%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    color: white;
    float: right;
    margin: 0px 10px 10px 0px;

    @media only screen and (max-width: 1400px) {
      .impacts-container {
        line-height: 20px;
      }
    }
  }
  .text-title {
    margin-top: 10px;
    font-size: 12px;
  }
  span {
    font-size: 16px;
    color: inherit;
  }

  h3 {
    color: inherit;
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
  }

  .ant-row-flex {
    align-items: baseline;
    flex-wrap: nowrap;
    gap: 5px;
  }
  .groupFilterButton {
    background-color: transparent;
    color: white;
    border-radius: 4px;
    padding: 4px 10px;
    height: 32px;
    border: 1px solid white;
    margin-top: 5px;
    .balanceButtonText {
      font-size: 12px;
    }
  }
  .content-info {
    margin-right: 10px;
    padding: 2px;
    border-radius: 4px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #3996F3;
    margin-bottom: 5px;
    span {
      font-size: 13px;
    }
  }
}`;

export default CartBudgetStyleHolder;